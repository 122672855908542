import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "homepage",
      component: () => import("@/views/home/HomePage.vue"),
    },
    {
      path: "/goodlist",
      name: "goodlist",
      component: () => import("@/views/good/GoodList.vue"),
    },
    {
      path: "/gooddetail",
      name: "gooddetail",
      component: () => import("@/views/gooddetail/GoodDetail.vue"),
    },
    {
      path: "/cart",
      name: "cart",
      component: () => import("@/views/cart/GoodCart.vue"),
    },
    {
      path: "/map",
      name: "map",
      component: () => import("@/views/map/map.vue"),
    },
  ],
});
export default router;

const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};
