<!-- <template>
  <div>
      <footer class="footer style7">
          <div class="container">
              <div class="container-wapper">
                  <div class="row">
                      <div class="box-footer col-xs-12 col-sm-4 col-md-4 col-lg-4 hidden-sm hidden-md hidden-lg">
                          <div class="dungar-newsletter style1">
                              <div class="newsletter-head">
                                  <h3 class="title">Newsletter</h3>
                              </div>
                              <div class="newsletter-form-wrap">
                                  <div class="list">
                                      Sign up for our free video course and <br> urban garden inspiration
                                  </div>
                                  <input type="email" class="input-text email email-newsletter"
                                      placeholder="Your email letter">
                                  <button class="button btn-submit submit-newsletter">SUBSCRIBE</button>
                              </div>
                          </div>
                      </div>
                      <div class="box-footer col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <div class="dungar-custommenu default">
                              <h2 class="widgettitle">Quick Menu</h2>
                              <ul class="menu">
                                  <li class="menu-item">
                                      <a href="/">New arrivals</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="/">Life style</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="/">TV &amp; Video</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="/">Headphones</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="/">Mouse</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div class="box-footer col-xs-12 col-sm-4 col-md-4 col-lg-4 hidden-xs">
                          <div class="dungar-newsletter style1">
                              <div class="newsletter-head">
                                  <h3 class="title">Newsletter</h3>
                              </div>
                              <div class="newsletter-form-wrap">
                                  <div class="list">
                                      Sign up for our free video course and <br> urban garden inspiration
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="box-footer col-xs-12 col-sm-4 col-md-4 col-lg-4">
                          <div class="dungar-custommenu default">
                              <h2 class="widgettitle">Information</h2>
                              <ul class="menu">
                                  <li class="menu-item">
                                      <a href="#">FAQs</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="#">Track Order</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="#">Delivery</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="/">Contact Us</a>
                                  </li>
                                  <li class="menu-item">
                                      <a href="#">Return</a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
                  <div class="footer-end">
                      <div class="row">
                          <div class="col-sm-12 col-xs-12">
                              <div class="dungar-socials">
                                  <ul class="socials" style="display: none;">
                                      <li>
                                          <a href="#" class="social-item" target="_blank">
                                              <i class="icon fa fa-facebook"></i>
                                          </a>
                                      </li>
                                      <li>
                                          <a href="#" class="social-item" target="_blank">
                                              <i class="icon fa fa-twitter"></i>
                                          </a>
                                      </li>
                                      <li>
                                          <a href="#" class="social-item" target="_blank">
                                              <i class="icon fa fa-instagram"></i>
                                          </a>
                                      </li>
                                  </ul>
                              </div>
                              <div class="coppyright">
                                      Copyright  2021-2023 
                                      <a target="_blank" href="https://ikuaijin.com">ikuaijin.com</a>
                                      All Rights Reserved.
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" >

</style> -->
<template>
    <div class="gobalFoot">
        <div class="foot">
            <div style="width:9.375rem;margin-top:125px">
                <img src="../assets/gobalFoot/logo.svg" alt style="width: 10.3125rem;" />
            </div>
            <div class="footCenter">
                <div class="name" @mouseover.stop="over" @mouseleave.stop="leave">快进时代（厦门）科技有限公司</div>

                <div class="phone" @mouseover.stop="over" @mouseleave.stop="leave" style="visibility: hidden;">
                    <div style="margin-top:3px">
                        <img src="../assets/gobalFoot/phone.svg" alt style="width:18px;height:18px" />
                    </div>
                    <div style="margin-left:5px">0592-5095708</div>
                </div>
                <div class="Official">
                    <el-popover placement="top" width="150" trigger="hover" popper-class="my-popover">
                        <div class="view" slot="reference">
                            <img src="../assets/gobalFoot/Official.svg">
                            <span>微信公众号</span>
                        </div>
                        <img src="../assets/gobalFoot/Official.svg">
                    </el-popover>

                    <el-popover placement="top" width="150" trigger="hover" popper-class="my-popover">
                        <div slot="reference" class="view">
                            <img src="../assets/gobalFoot/wxvideo.svg">
                            <span>官方视频号</span>
                        </div>
                        <img src="../assets/gobalFoot/wxvideo.svg">
                    </el-popover>
                </div>
              <div data-v-55cbc4c3="" style="font-size: 0.75rem; margin-top: 5px; display: flex; align-items: center;">
                <a data-v-55cbc4c3="" target="_blank" href="https://beian.miit.gov.cn/" style="font-size: 0.75rem; color: rgb(255, 255, 255); text-decoration: none;">闽ICP备2021012124号-2</a>
              </div>
            </div>
            <div class="submit">
                <div class="cooperation">合作咨询</div>
                <div class="phone" @mouseover.stop="over" @mouseleave.stop="leave">
                    <div style="margin-top:3px;display:inline;">
                        <img src="../assets/gobalFoot/phone.svg" alt style="width:18px;height:18px" />
                    </div>
                    <div style="margin-left:5px;display:inline;">0592-5095708</div>
                </div>
                <div class="letter" @mouseover.stop="over" @mouseleave.stop="leave">
                    <div style="margin-top:5px;display:inline;">
                        <img src="../assets/gobalFoot/letter.svg" alt style="width:18px;height:18px" />
                    </div>
                    <div style="margin-left:5px;display:inline;">service@ikuaijin.com</div>
                </div>
                <div class="letter" @mouseover.stop="over" @mouseleave.stop="leave" style="margin-top: 47px;">
                    <div style="height:40px;border-bottom: 1px solid white;">
                        <div style="display:inline;">咨询请联系我们~</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
        };
    },
    mounted() { },
    methods: {
        over() {
            document.oncontextmenu = function () {
                event.returnValue = true;
            }
        },
        leave() {
            document.oncontextmenu = function () {
                event.returnValue = false;
            }
        },
        routeGo(path) {
            if (this.$route.path == path) {
                scrollTo(0, 0);
            } else {
                this.$router.push(path);
            }

        },
    }
};
</script>
<style scoped>
.my-popover {
    padding: 0px;
}

::-webkit-input-placeholder {
    color: #cccccc;
    font-family: opposr, sans-serif;
    letter-spacing: 1px;
}

input:focus {
    outline: none;
}

textarea:focus {
    outline: none;
}

textarea {
    padding-top: 8px;
    padding-left: 10px;
    border-radius: 3px;
    resize: none;
    border: white;
    color: #181818;
}

input {
    border-radius: 2px;
    padding-left: 10px;
    color: #181818;
}

.gobalFoot {
    width: 100%;
    background: rgb(31, 16, 224);
    height: 370px;
    padding-bottom: 2.5rem;
    font-family: opposr, sans-serif;
    display: block;
}

.gobalFoot .foot {
    width: 61%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.gobalFoot .foot .footCenter {
    margin-top: 90px;
    font-family: opposm, sans-serif;
    color: #ffffff;
}

.gobalFoot .foot .footCenter .name {
    margin-top: -3px;
    padding-bottom: 15px;
    border-bottom: 1px solid white;
    font-size: 1.125rem;
    letter-spacing: 1px;
    -moz-user-select: text;
    -webkit-user-select: text;
    user-select: text;
}

.gobalFoot .foot .footCenter .phone {
    margin-top: 20px;
    display: flex;
    align-items: center;
    -moz-user-select: text;
    -webkit-user-select: text;
    user-select: text;
    font-size: 1rem;
}

.gobalFoot .foot .footCenter .letter {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    font-size: 1rem;
    margin-top: -5px;
    -moz-user-select: text;
    -webkit-user-select: text;
    user-select: text;
}

.gobalFoot .foot .footCenter .Official {
    display: flex;
    align-items: center;
    border-bottom: 1px solid white;
    padding-bottom: 19px;
}

.gobalFoot .foot .footCenter .Official .view {
    text-align: center;
}

.gobalFoot .foot .footCenter .Official .view:nth-child(1) {
    margin-right: 30px;
}

.gobalFoot .foot .footCenter .Official .view img {
    display: block;
    width: 4.4375rem;
    height: 4.4375rem;
}

.gobalFoot .foot .footCenter .Official .view span {
    margin: 0px auto;
    display: inline-block;
    margin-top: .4375rem;
    font-size: .875rem;
    color: #FFFFFF;
}

.gobalFoot .foot .footCenter .title {
    margin-top: 20px;
    font-size: .875rem;
}

.gobalFoot .submit {
    margin-top: 89px;
    color: #ffffff;
    font-size: 1.125rem;
}

.gobalFoot .submit .cooperation {
    font-size: 1.125rem;
    margin-bottom: 30px;
    font-family: opposm;
    padding-bottom: 14px;
    border-bottom: 1px solid white;
}

.gobalFoot .submit input {
    width: 25rem;
    margin: 10px 0;
}

.gobalFoot .submit input:nth-child(1) {
    margin-top: 0px;
}

.gobalFoot .submit textarea {
    width: 25rem;
    margin: 4px 0;
    margin-top: 0px;
}

.gobalFoot .submit input::-webkit-input-placeholder {
    color: #b2b2b2;
    font-size: 0.8rem;
}

.gobalFoot .submit textarea::-webkit-input-placeholder {
    color: #b2b2b2 !important;
    font-family: opposr, sans-serif;
    letter-spacing: 1px;
    font-size: 0.8rem;
}

.gobalFoot .submit .btn {
    width: 25.5rem;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #ff3162;
    margin-top: 7px;
    border-radius: 2px;
    font-size: .875rem;
    cursor: pointer;
}
</style>
