import request from "@/utils/request"



export function login(data) {
    return request({
        url: "/mall/login",
        method: "POST",  // GET -> POST 
        data: data,        //params ->  data
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
}

export function pay(data) {
    return request({
        url: "/mall/pay",
        method: "POST",  // GET -> POST 
        data: {
            fee:data
        },   
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
}

export function goodsType(data) {
    return request({
        url: "/mall/goodsType",
        method: "GET",  // GET -> POST 
        params: data,        //params ->  data
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
}

export function merchantList(data) {
    return request({
        url: "/mall/merchantList",
        method: "GET",  // GET -> POST 
        params: data,        //params ->  data
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
}

export function goodsList(data, id) {
    console.log(data);
    return request({
        url: "/mall/goodsList",
        method: "POST",  // GET -> POST 
        data: {
            basicClassId: id,
            isHaveImg: 1,
            pageSize: data.pageSize,
            pageNum: data.pageNum
        },        //params ->  data
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
}

export function goodsGet(data) {
    return request({
        url: "/mall/goodsGet",
        method: "POST",  // GET -> POST 
        data: {
            goodsId: data
        },        //params ->  data
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        }
    })
}


