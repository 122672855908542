<template>
  <div>
    <header class="header style7">
      <div class="container">
        <div class="main-header">
          <div class="row">
            <div
              class="col-lg-3 col-sm-4 col-md-3 col-xs-7 col-ts-12 header-element"
            >
              <div class="logo" style="margin-top: 10px">
                <span
                  style="font-size: 28px; color: #666666; letter-spacing: 2px"
                >
                  &ensp;欢迎您!</span
                >
              </div>
            </div>
            <div class="col-lg-7 col-sm-8 col-md-6 col-xs-5 col-ts-12">
              <div class="block-search-block">
                <div class="form-search form-search-width-category">
                  <div class="form-content">
                    <div class="category">
                      <div
                        class="chosen-container chosen-container-single chosen-container-single-nosearch"
                        style="width: 148px"
                        title="cate"
                      >
                        <div class="chosen-drop">
                          <div class="chosen-search">
                            <input
                              type="text"
                              autocomplete="off"
                              readonly=""
                              tabindex="1"
                            />
                          </div>
                          <ul class="chosen-results"></ul>
                        </div>
                      </div>
                    </div>
                    <div class="inner">
                      <input
                        type="text"
                        class="input"
                        name="s"
                        value=""
                        placeholder="请输入商品名称"
                      />
                    </div>
                    <button class="btn-search">
                      <span class="icon-search"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: "",
      password: "",
      visibility: false,
      token: "",
      num: 0,
      cart: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getUser();
    this.getCart();
    this.$bus.$on("cart", (value) => {
      this.getCart();
    });
  },
  methods: {
    getUser() {
      this.username = localStorage.getItem("username");
    },
    getCart() {
      this.num = 0;
      this.cart = JSON.parse(localStorage.getItem(`carts_${this.username}`))
        ? JSON.parse(localStorage.getItem(`carts_${this.username}`))
        : [];
      for (const item of this.cart) {
        this.num += item.num;
      }
    },
    async login() {
      if (!this.username || !this.password)
        return this.$message.error("请填写账号或密码");
      let req = {
        username: this.username,
        password: this.password,
      };
      let res = await login(req);
      if (res.code == 200) {
        this.$message.success(res.msg);
        localStorage.setItem("token", res.token);
        localStorage.setItem("username", this.username);
        this.visibility = true;
        this.$router.push("/") && window.location.reload();
      } else {
        this.$message.error(res.msg);
      }
    },
    clickCart() {
      if (localStorage.getItem("token")) {
        this.$router.push("/cart");
      } else {
        this.$message.warning("请先登录再进行购物哦!");
      }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      this.$router.push("/") && window.location.reload();
    },
  },
};
</script>

<style>
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: white !important;
  color: #1f10e0 !important;
}
</style>