<template>
  <div id="app">
    <NavBar v-show="!(path === '/map')"></NavBar>
    <LogoNav v-show="!(path === '/map')"></LogoNav>
    <NavTitle v-show="!(path === '/map')"></NavTitle>
    <router-view></router-view>
    <FooterBar v-show="!(path === '/map')"></FooterBar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import LogoNav from "@/components/LogoNav.vue";
import NavTitle from "@/components/NavTitle.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
    LogoNav,
    NavTitle,
    FooterBar,
  },
  data() {
    return {
      path: "",
    };
  },
  // 判断路由
  mounted() {
    this.path = this.$route.path;
    // console.log(this.$route.path)
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
};
</script>

<style>
html {
  font-size: 16px;
}
#app{
  height: 100%;
}
</style>
