<template>
  <div>
    <header class="header style7">
      <div class="top-bar" style="background-color: #1f10e0">
        <div class="container">
          <div class="top-bar-left">
            <div
              class="header-message"
              style="font-size: x-large; cursor: pointer"
              @click="
                () => {
                  this.$router.replace('/');
                }
              "
            >
              <img
                src="@/assets/gobalFoot/logo2.png"
                alt="img"
                style="width: 147px; height: 35px; margin-left: -6px"
              />
            </div>
          </div>
          <div
            style="
              display: inline-block;
              float: right;
              margin-top: 14px;
              margin-left: 20px;
              font-size: 17px;
              cursor: pointer;
            "
            @click="dialogVisible = true"
            v-if="this.token"
          >
            <i class="el-icon-sell"></i> 发布
          </div>
          <div
            class="header-control"
            style="display: inline-block; float: right"
          >
            <div
              class="block-minicart dungar-mini-cart block-header dungar-dropdown"
            >
              <a
                @click="clickCart"
                class="shopcart-icon"
                style="cursor: pointer; display: none"
                data-dungar="dungar-dropdown"
              >
                Cart
                <div v-if="num > 0 && this.token">
                  <span class="count" style="background-color: #1f10e0">
                    {{ num }}
                  </span>
                </div>
              </a>
            </div>
            <div class="block-account block-header dungar-dropdown">
              <el-dropdown style="color: white; cursor: pointer">
                <a data-dungar="dungar-dropdown">
                  <span style="font-size: 23px;margin-right:30px" v-if="!this.token"><i class="el-icon-sell" ></i> 发布</span>
                  <span class="flaticon-user" style="font-size: 26px"></span>
                  <span
                    v-if="this.token"
                    style="font-size: 20px; margin-left: 15px"
                    >{{ username }}</span
                  >
                </a>
                <el-dropdown-menu slot="dropdown" v-if="this.token">
                  <el-dropdown-item
                    ><a @click="logout()">
                      <img
                        src="@/assets/退出登录.png"
                        style="width: 18px; height: 18px; margin-bottom: 7px"
                      />
                      退出登录</a
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div class="header-account dungar-submenu" v-if="!this.token">
                <div class="header-user-form-tabs">
                  <ul class="tab-link">
                    <li class="active">
                      <a
                        data-toggle="tab"
                        aria-expanded="true"
                        href="#header-tab-login"
                        >登录</a
                      >
                    </li>
                    <!-- <li>
                                                                            <a data-toggle="tab" aria-expanded="true"
                                                                                href="#header-tab-rigister">Register</a>
                                                                        </li> -->
                  </ul>
                  <div class="tab-container">
                    <div id="header-tab-login" class="tab-panel active">
                      <form method="post" class="login form-login">
                        <p class="form-row form-row-wide">
                          <input
                            type="text"
                            v-model="username"
                            placeholder="请输入用户名"
                            class="input-text"
                          />
                        </p>
                        <p class="form-row form-row-wide">
                          <input
                            type="password"
                            v-model="password"
                            class="input-text"
                            placeholder="请输入密码"
                          />
                        </p>
                        <p class="form-row">
                          <input
                            type="button"
                            @click="login"
                            class="button"
                            value="登录"
                          />
                        </p>
                      </form>
                    </div>
                    <div id="header-tab-rigister" class="tab-panel">
                      <form method="post" class="register form-register">
                        <p class="form-row form-row-wide">
                          <input
                            type="email"
                            placeholder="Email"
                            class="input-text"
                          />
                        </p>
                        <p class="form-row form-row-wide">
                          <input
                            type="password"
                            class="input-text"
                            placeholder="Password"
                          />
                        </p>
                        <p class="form-row">
                          <input
                            type="submit"
                            class="button"
                            value="Register"
                          />
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a class="menu-bar mobile-navigation menu-toggle" href="#">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </header>
    <el-dialog
      title="发布成功"
      :visible.sync="dialog"
      width="30%"
      :before-close="handleClose1"
    >
      <el-form ref="form" :model="form" label-width="90px">
        <el-form-item label="商品条码:" prop="code">
          <span>{{ form.code }}</span>
        </el-form-item>
        <el-form-item label="商户名称:" prop="name">
          <span>{{ form.name }}</span>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <span>{{ form.address }}</span>
        </el-form-item>
        <el-form-item label="手机号码:" prop="tel">
          <span>{{ form.tel }}</span>
        </el-form-item>
        <el-form-item label="营业时间:" prop="time">
          <span>{{ form.time }}</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="发布"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="90px" :rules="rules">
        <el-form-item label="商品条码:" prop="code">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="商户名称:" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="地址：" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="手机号码:" prop="tel">
          <el-input v-model="form.tel" type="number"></el-input>
        </el-form-item>
        <el-form-item label="营业时间:" prop="time">
          <el-date-picker
            v-model="form.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="pay">发 布</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      width="400px"
      center
    >
      <div
        style="
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        "
      >
        请使用微信扫一扫
      </div>
      <div
        style="
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
        "
      >
        扫描下方二维码
      </div>
      <div style="display: flex; justify-content: center; align-items: center">
        <img :src="QRImgUrl" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click="commit"
          style="margin-top: 10px; margin-left: 20px"
          >已支付成功，点击下一步</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { login } from "@/api/homepage";
import { pay } from "@/api/homepage";
import QRCode from "qrcode";

export default {
  data() {
    return {
      username: "",
      password: "",
      visibility: false,
      token: "",
      num: 0,
      cart: [],
      dialogVisible: false,
      centerDialogVisible: false,
      form: {
        code: null,
        name: null,
        address: null,
        tel: null,
        time: null,
      },
      rules: {
        code: [{ required: true, message: "请输入商品条码", trigger: "blur" }],
        name: [{ required: true, message: "请输入商户名称", trigger: "blur" }],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        tel: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
        time: [{ required: true, message: "请输入营业时间", trigger: "blur" }],
      },
      QRImgUrl: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getUser();
    this.getCart();
    this.$bus.$on("cart", (value) => {
      this.getCart();
    });
  },
  methods: {
    getQRcode(text) {
      let opts = {
        errorCorrectionLevel: "L", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 1, //二维码质量
        margin: 1, //二维码留白边距
        width: 280, //宽
        height: 280, //高
        text, //二维码内容
        color: {
          dark: "#000000", //前景色
          light: "#f8f8f8", //背景色
        },
      };
      QRCode.toDataURL(text, opts, (err, url) => {
        if (err) throw err;
        //将生成的二维码路径复制给data的QRImgUrl
        this.QRImgUrl = url;
      });
    },
    async pay() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          localStorage.setItem("good", JSON.stringify(this.form));
          let res = await pay(20);
          this.centerDialogVisible = true;

          if (res.code == 200) {
            this.getQRcode(res.codeUrl);
          } else {
            this.$message.error(res.msg);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    commit() {
      this.centerDialogVisible = false;
      this.dialogVisible = false;
      this.form = JSON.parse(localStorage.getItem("good"));
      this.form.time = this.formatDateRange(this.form.time);
      this.dialog = true;
    },
    handleClose1() {
      this.dialog = false;
      this.form = {
        code: null,
        name: null,
        address: null,
        tel: null,
        time: null,
      };
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {
        code: null,
        name: null,
        address: null,
        tel: null,
        time: null,
      };
    },
    getUser() {
      this.username = localStorage.getItem("username");
    },
    getCart() {
      this.num = 0;
      this.cart = JSON.parse(localStorage.getItem(`carts_${this.username}`))
        ? JSON.parse(localStorage.getItem(`carts_${this.username}`))
        : [];
      for (const item of this.cart) {
        this.num += item.num;
      }
    },
    async login() {
      if (!this.username || !this.password)
        return this.$message.error("请填写账号或密码");
      let req = {
        username: this.username,
        password: this.password,
      };
      let res = await login(req);
      if (res.code == 200) {
        this.$message.success(res.msg);
        localStorage.setItem("token", res.token);
        localStorage.setItem("username", this.username);
        this.visibility = true;
        this.$router.push("/") && window.location.reload();
      } else {
        this.$message.error(res.msg);
      }
    },
    clickCart() {
      if (localStorage.getItem("token")) {
        this.$router.push("/cart");
      } else {
        this.$message.warning("请先登录再进行购物哦!");
      }
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      this.$router.push("/") && window.location.reload();
    },
    formatDateRange(dateRange) {
      const start = new Date(dateRange[0]);
      const end = new Date(dateRange[1]);

      const startDateString = this.getDateFormattedString(start);
      const endDateString = this.getDateFormattedString(end);

      return `${startDateString} 至  ${endDateString}`;
    },

    getDateFormattedString(date) {
      const year = date.getFullYear();
      const month = this.padNumberWithZero(date.getMonth() + 1); // 月份从0开始，需要加1
      const day = this.padNumberWithZero(date.getDate());
      const hours = this.padNumberWithZero(date.getHours());
      const minutes = this.padNumberWithZero(date.getMinutes());
      const seconds = this.padNumberWithZero(date.getSeconds());

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    padNumberWithZero(number) {
      return number < 10 ? `0${number}` : number;
    },
  },
};
</script>

<style>
a[data-dungar="dungar-dropdown"]:hover {
  color: #fff; /* 设置文本颜色为白色 */
}
a[data-dungar="dungar-dropdown"] {
  color: #fff; /* 设置文本颜色为白色 */
}
</style>