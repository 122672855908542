<template>
    <div>
        <header class="header style7">
            <div class="header-nav-container rows-space-20">
                <div class="container">
                    <div class="header-nav-wapper main-menu-wapper">
                        <div class="vertical-wapper block-nav-categori" style="display: none;">
                            <div class="block-title" >
                                <span class="icon-bar">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span class="text">全部商户</span>
                            </div>
                            <div class="block-content verticalmenu-content"
                                style="max-height: 400px;overflow: hidden;overflow-y: auto;">
                                <ul class="dungar-nav-vertical vertical-menu dungar-clone-mobile-menu"
                                    v-for="item in merchantList" :key="item.key">
                                    <!-- <li class="menu-item" style="cursor: pointer;"
                                                            @click="() => { this.$router.push('/goodlist') }">
                                                            <a class="dungar-menu-item-title" title="New Arrivals">商品列表</a>
                                                        </li> -->
                                    <li class="menu-item" style="cursor: pointer;">
                                        <a @click="routerPush({key:item.key, value:item.value})" class="dungar-menu-item-title"
                                            title="New Arrivals">{{ item.value }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="header-nav">
                            <div class="container-wapper">
                                <ul class="dungar-clone-mobile-menu dungar-nav main-menu " id="menu-main-menu"
                                    v-for="item in goodsTypeList" :key="item.id" style="float:left;">
                                    <li class="menu-item  menu-item-has-children">
                                        <a class="dungar-menu-item-title">{{
                                            item.name
                                        }}</a>
                                        <span class="toggle-submenu"></span>
                                        <ul class="submenu">
                                            <li class="menu-item menu-item-has-children" v-for="i in (item.children || [])"
                                                :key="i.id" style="cursor: pointer;">
                                                <a @click="routerPush({id:i.id, name:i.name})" class="dungar-menu-item-title">{{
                                                    i.name
                                                }}
                                                </a>
                                                <span class="toggle-submenu"></span>
                                                <ul class="submenu">
                                                    <li class="menu-item" v-for="j in (i.children || [])" :key="j.id">
                                                        <a @click="routerPush({id:j.id, name:j.name})" v-if="j.id != 160">{{ j.name }}</a>
                                                    </li>
                                                </ul>
                                            </li>

                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>

<script>
import { goodsType } from '@/api/homepage';
import { merchantList } from '@/api/homepage';
export default {
    data() {
        return {
            goodsTypeList: [],
            merchantList: [],
            goodsTypeItem: []
        }
    },
    created() {
        this.getGoodsType()
        this.getMerchantList()
    },
    methods: {
        async getGoodsType() {
            let res = await goodsType()
            if (res.code == 200) {
                this.goodsTypeList = (res.data.splice(0, 8) || [])
            } else {
                this.goodsTypeList = []
            }
        },
        async getMerchantList() {
            let req = await merchantList()
            if (req.code == 200) {
                this.merchantList = req.data
            } else {
                this.merchantList = []
            }
        },
       
        routerPush(obj) {
            if(this.$route.path=="/goodlist") {
                this.$bus.$emit("goodsList",obj)
            }else {
                this.$router.push({
                    name: 'goodlist',
                    path:'/goodlist',
                    params: obj
                })
            }
            
        }
    },
}
</script>

<style lang="scss"></style>